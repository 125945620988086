















import { UserStatDateRecord } from '@/includes/types/UserProfile/types'
import { ApexLocales } from '@/views/chat/statistics/logic/defaultApexSettings'

import { seriesByMetricInList } from 'piramis-base-components/src/shared/utils/seriesByMetricInList'
import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'
import { ApexChartSeries } from 'piramis-base-components/src/shared/types/ApexChartSeries.types'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import VueApexCharts from 'vue-apexcharts'
import { ApexOptions } from 'apexcharts'

@Component({
  components: {
    VueApexCharts
  }
})
export default class UserProfileFullChart extends Vue {

  @Prop({ default: () => [] }) statistics!:Array<UserStatDateRecord> | undefined

  @Prop() from!:string

  @Prop() to!:string

  chartData: ApexChartSeries | null = []

  get ticks() {
    return fromToTicks(this.from, this.to)
  }

  chartOptions:ApexOptions = {
    chart: {
      defaultLocale: this.$i18n.locale,
      locales: ApexLocales,
      stacked: true,
      animations: {
        enabled: false
      },
      toolbar: {
        show: true,
        tools: {
          download: false,
        }
      },
      type: 'bar',
      height: 350,
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
    },
    labels: [],
    grid: {
      show: false,
    },
    colors: [ 'rgba(var(--a-primary), 1)', 'rgba(var(--a-warning), 1)' ],
    xaxis: {
      type: 'datetime',
      categories: this.ticks.timeTicks,
    },
    yaxis: {
      labels: {
        formatter(val: number, opts?: any): string | string[] {
          return val.toString()
        }
      },
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      }
    },
    tooltip: {
      x: { show: false },
    },
  }

  created() {
    if (this.statistics) {

      const { calcSeries } = seriesByMetricInList(this.$i18n, this.statistics, [ 'message_count', 'reply_count' ], this.ticks, 'date')

      calcSeries()
        .then(res => {
          this.chartData = res
        })
    }
  }
}
