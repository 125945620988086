<template>
  <a-table
    :data-source="referrals"
    :pagination="{
      'default-page-size': itemsPerPage,
      'show-size-changer': true,
      'show-quick-jumper': true,
    }"
    :rowKey="record => record.user_id"
    expandRowByClick
  >
    <template slot="title">
      {{ $t('user_info_generated_at', [fullDate]) }}
    </template>
    <a-table-column
      key="index"
      :sorter="(a, b) => a.index - b.index"
      :sortDirections="[ 'descend', 'ascend' ]"
      data-index="index"
    >
      <a-icon
        slot="title"
        type="number"
      />
    </a-table-column>

    <a-table-column key="userInfo">
      <a-icon
        slot="title"
        type="user"
      />
      <template slot-scope="text, record">
        <user-main-info
          :avatar="record.avatar"
          :login="record.user_login"
          :name="record.user_name"
        />
      </template>
    </a-table-column>
    <div
      slot="expandedRowRender"
      slot-scope="record"
    >
      <table-user-action-buttons
        :id="record.user_id"
        :login="record.user_login"
      />
    </div>
  </a-table>
</template>

<script>
import moment from "moment";
import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo'
import TableUserActionButtons from "@/components/TableUserActionButtons";

export default {
  name: "UserReferralsTable",
  components: { TableUserActionButtons, UserMainInfo },
  props: [ 'referral', 'dataGenTime' ],

  data() {
    return {
      itemsPerPage: 10,
    }
  },

  computed: {
    fullDate() {
      return moment(this.dataGenTime).locale(this.$i18n.locale).format('LLLL')
    },
    referrals() {
      if (this.referral) {
        return this.referral.referral_users.map((u, index) => ({ ...u, index: index + 1 }))
      }
      return []
    },
  },
}
</script>

<style scoped>

</style>
