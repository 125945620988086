























































import { UserInteractionJournalRecord, UsersProfileStat, UserStat } from '../../includes/types/UserProfile/types'
import TableItem from '@/views/chat/journal/table/table-item.vue'
import { JournalRecordUserFields } from '@/views/chat/journal/types'

import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import moment from 'moment'
import { snakeCase } from 'lodash'

@Component({
  components: {
    TableItem,
    UserMainInfo,
  },
  data() {
    return {
      snakeCase
    }
  }
})
export default class UserProfileJournalTimeline extends Vue {
  @Prop({ type: Array, default: () => [] }) journalActor!:UserStat['journal_actor']

  @Prop({ type: Array, default: () => [] }) journalTarget!:UserStat['journal_target']

  @Prop() users!:UsersProfileStat['users']

  isJournalInfoModalOpen = false

  activeItem:UserInteractionJournalRecord | null = null

  get journalTimeline() {
    if (this.journalActor && this.journalTarget) {
      const journalActor = this.journalActor.map(j => ({ ...j, type: 'actor' }))
      const journalTarget = this.journalTarget.map(j => ({ ...j, type: 'target' }))

      return [
        ...journalActor,
        ...journalTarget
      ]
        .sort((a, b) => moment(b.time).valueOf() - moment(a.time).valueOf())
    }
  }

  beautifyDate(date: string) {
    return moment(date).format('LLL')
  }

  handleTimelineItem(journal: UserInteractionJournalRecord): void {
    const getUserInfo = (id: number, prefix: 'actor' | 'target') => {

      return Object.entries(this.users[id] ?? {}).reduce((acc: JournalRecordUserFields, [ k, v ]) => {
        const key = `${ prefix }_user_${ k }` as keyof JournalRecordUserFields

        (acc[key] as any) = v

        return acc
      }, {} as JournalRecordUserFields)
    }

    Object.assign(journal, getUserInfo(journal.actor_user_id, 'actor'))
    Object.assign(journal, getUserInfo(journal.target_user_id, 'target'))

    this.activeItem = journal
    this.isJournalInfoModalOpen = true
  }
}
