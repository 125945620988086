<template>
  <div class="user-info__avatar w-full">
    <img
      class="user-info__avatar__image"
      :src="src"
      alt="user-avatar"
      ref="avatar"
    >
  </div>
</template>

<script>
import mediumZoom from 'medium-zoom'

export default {
  name: "UserInfoAvatar",
  props: [ "src" ],
  mounted() {
    mediumZoom('.user-info__avatar img')
  }
}
</script>

<style scoped lang="scss">
.user-info {
  &__avatar {
    overflow: hidden;
    border-radius: 12px;
    min-width: 150px;
    position: relative;
    display: inline-flex;
    border: .3rem solid #fff;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.3);
    max-width: 150px;

    &:hover {
      .user-info__avatar__image {
        transform: scale(1.2);
      }
    }

    &__image {
      height: 100%;
      width: 100%;
      background-position: center center;
      background-size: cover;
      transition: all .2s cubic-bezier(0.4, 0.0, 0.2, 1);
    }
  }
}
</style>
