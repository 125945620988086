















import { UserStatWeekDayRecord } from '@/includes/types/UserProfile/types'
import MetricCard from '@/components/CommunityDashboard/components/MetricCard.vue'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ApexOptions } from 'apexcharts'

import VueApexCharts from 'vue-apexcharts'

@Component({
  components: {
    VueApexCharts,
    MetricCard
  }
})
export default class UserProfileWeekDay extends Vue {
  @Prop() statistics!: Array<UserStatWeekDayRecord> | undefined

  weekDaysTranslations: Record<string, string> = {
    '1': this.$t('monday').toString(),
    '2': this.$t('tuesday').toString(),
    '3': this.$t('wednesday').toString(),
    '4': this.$t('thursday').toString(),
    '5': this.$t('friday').toString(),
    '6': this.$t('saturday').toString(),
    '7': this.$t('sunday').toString(),
  }

  get weekDays() {
    return Array.from({ length: 7 }, (el, i) => i+1)
  }

  get chartSeries() {
    if (this.statistics) {
      const map = this.weekDays.reduce((acc: Record<string, number>, currentValue) => {
        acc[currentValue] = 0
        return acc
      }, {})

      this.statistics.forEach(r => {
        map[r.day] = r.message_count
      })

      return [ {
        name: 'week-day-msg',
        data: Object.values(map)
      } ]
    }
  }

  get chartOptions(): ApexOptions {
    return {
      chart: {
        type: 'bar',
        animations: {
          enabled: false
        },
        sparkline: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          distributed: true,
          horizontal: false,
        },
      },
      colors: [
        'rgba(var(--a-primary), 1)',
        'rgba(var(--a-primary), 1)',
        'rgba(var(--a-primary), 1)',
        'rgba(var(--a-primary), 1)',
        'rgba(var(--a-primary), 1)',
        'rgba(var(--a-danger), 1)',
        'rgba(var(--a-danger), 1)'
      ],
      dataLabels: {
        enabled: false
      },
      grid: {
        show: false,
        padding:{
          right: 0,
          left: 0,
          bottom: 0,
          top: 0
        }
      },
      xaxis: {
        labels: {
          show: false,
          formatter: (value: string, timestamp?: number, opts?: any): string | string[] => {
            return this.weekDaysTranslations[value]
          }
        },
        categories: this.weekDays
      },
      tooltip: {
        marker: {
          show: false
        },
        y: {
          formatter: (val: number): string => val.toFixed(0).toString(),
          title: {
            formatter: (seriesName: string): string => ''
          }
        }
      }
    }
  }
}
