export const UserHelpers = {
    getId(userData) {
        return userData.user_id
    },

    getLogin(userData) {
        return userData.login
    },

    getLanguageCode(userData) {
        return userData.language_code;
    },

    getName(userData) {
        return userData.name;
    },

    getAvatarUrl(userData) {
        return userData.avatar;
    },

    getDirectLink(userData) {
        if(userData.login) {
            let login = userData.login;
            while(login.charAt(0) === "@") {
                login = login.substr(1);
            }
            return `tg://resolve?domain=${ login }`;
        }
    },

    getUserPageLink(userData, chat_id) {
        return `/chat/${ chat_id }/user-info/${ userData.user_id }`
    }
}
