















import { UserStatHourRecord } from '@/includes/types/UserProfile/types'
import MetricCard from '@/components/CommunityDashboard/components/MetricCard.vue'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ApexOptions } from 'apexcharts'

import VueApexCharts from 'vue-apexcharts'

@Component({
  components: {
    VueApexCharts,
    MetricCard
  }
})
export default class UserProfileWeekDay extends Vue {
  @Prop() statistics!: Array<UserStatHourRecord> | undefined

  get hours() {
    return Array.from<number, number>({ length: 24 }, (el, i) => i)
  }

  get chartSeries() {
    if (this.statistics) {
      const map = this.hours.reduce((acc: Record<string, number>, currentValue) => {
        acc[currentValue] = 0
        return acc
      }, {})

      this.statistics.forEach(r => {
        map[r.hour] = r.message_count
      })

      return [ {
        name: "week-day-msg",
        data: Object.values(map)
      } ]
    }
  }

  get chartOptions(): ApexOptions {
    return {
      chart: {
        type: 'bar',
        animations: {
          enabled: false
        },
        sparkline: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        show: false,
        padding:{
          right: 0,
          left: 0,
          bottom: 0,
          top: 0
        }
      },
      xaxis: {
        labels: {
          show: false,
          formatter(value: string, timestamp?: number, opts?: any): string | string[] {
            if (Number.parseInt(value) < 10) {
              return `0${ value }:00`
            }

            return `${ value }:00`
          }
        },
        categories: this.hours.map(h => h.toString())
      },
      tooltip: {
        marker: {
          show: false
        },
        y: {
          formatter: (val: number): string => val.toFixed(0).toString(),
          title: {
            formatter: (seriesName: string): string => ''
          }
        }
      }
    }
  }
}
