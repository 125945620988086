import { render, staticRenderFns } from "./UserProfileInfo.vue?vue&type=template&id=032e7e39&scoped=true&"
import script from "./UserProfileInfo.vue?vue&type=script&lang=ts&"
export * from "./UserProfileInfo.vue?vue&type=script&lang=ts&"
import style0 from "./UserProfileInfo.vue?vue&type=style&index=0&id=032e7e39&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "032e7e39",
  null
  
)

export default component.exports